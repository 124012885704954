import React, { Children } from 'react'
import Code from './code'
import { GlobalContext } from '../context'

export class Switcher extends React.Component {
  static defaultProps = {
    active: undefined
  }

  constructor(props) {
    super(props)

    this.state = {
      active: props.active || 'none'
    }
  }

  onChange(label) {
    this.setState({ active: label })
    if (this.props.onChange) {
      this.props.onChange(label)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active) {
      this.setState({active: this.props.active})
    }
  }

  render() {
    return (
      <div>
        <div>
        {Children.map(this.props.children, child => (
          <a
            href={'#'}
            style={{
              padding: 10,
              fontWeight: child.props.label === this.state.active ? 600 : 300
            }}
            onClick={() => this.onChange(child.props.label)}>
            {child.props.label || 'none'}
          </a>
        ))}
        </div>

        {Children.map(this.props.children, child => (
          <div
            style={{
              display:
                child.props.label === this.state.active ? 'inherit' : 'none'
            }}>
            {child}
          </div>
        ))}
      </div>
    )
  }
}

export class CodeSwitcher extends React.Component {
  render() {
    return (
      <GlobalContext.Consumer>
        {({ defaultProgrammingLanguage, update }) => {
          const language =
            this.props.active ||
            defaultProgrammingLanguage ||
            Object.keys(this.props.languages)[0]
          return (
            <Switcher
              active={language}
              onChange={l => update('defaultProgrammingLanguage', l)}>
              {Object.entries(this.props.languages).map(([lang, code]) => (
                <Code label={lang} language={lang} key={lang}>
                  {code}
                </Code>
              ))}
            </Switcher>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}
