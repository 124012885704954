import React from 'react'
import Prism from 'prismjs'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-bash'

export default class Code extends React.Component {
  static defaultProps = {
    language: undefined
  }

  constructor(props) {
    super(props)

    this.state = {
      formattedCode: this.formatCode(props.children, props.language)
    }
  }

  formatCode(code, language) {
    if (!code) return undefined
    if (!(language in Prism.languages)) {
      console.warn(
        `unsupported language (${language}), 
        might need to explicitly import it as such:
        import 'prismjs/components/prism-${language}'
        `
      )
      return undefined
    }
    return Prism.highlight(code, Prism.languages[language], language)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      this.setState({
        formattedCode: this.formatCode(this.props.children, this.props.language)
      })
    }
  }

  render() {
    return (
      <div className="gatsby-highlight" data-language={this.props.language}>
        <pre className={`language-${this.props.language}`}>
          {this.state.formattedCode ? (
            <code
              className={`language-${this.props.language}`}
              dangerouslySetInnerHTML={{ __html: this.state.formattedCode }}
            />
          ) : (
            <code className={`language-${this.props.language}`}>
              {this.props.children}
            </code>
          )}
        </pre>
      </div>
    )
  }
}
