import React from 'react'

import { navigate } from 'gatsby'
import { Location } from '@reach/router'

import DeveloperAPI, { isBrowser } from '../services/DeveloperAPIClient'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  if (!isBrowser()) {
    return null
  }
  if (!DeveloperAPI.isAuthenticated()) {
    navigate('/login/', { replace: true })
    return null
  }

  return <Component {...rest} />
}

/**
 * Root component that checks the location and redirects to login if user is not authenticated
 * redirects to home on login and forgot password if user is authenticated
 */
export const AuthRedirect = ({
  children,
  excludedPaths = ['/login/', '/forgot_password/', '/logout/', '/signup/'],
  loginPath = '/login/',
  forgotPasswordPath = '/forgot_password/'
} = {}) => {
  return (
    <Location>
      {({ location }) => {
        if (!isBrowser()) return children
        if (DeveloperAPI.isAuthenticated()) {
          if (
            location.pathname === loginPath ||
            location.pathname === forgotPasswordPath
          ) {
            navigate('/', { replace: true })
            return null
          } else {
            return children
          }
        }

        for (let p of excludedPaths) {
          if (location.pathname === p) {
            return children
          }
        }
        navigate('/login/', { replace: true })
        return null
      }}
    </Location>
  )
}

export const PrivateSwitchRoute = ({ logIn, loggedIn }) => {
  return DeveloperAPI.isAuthenticated() ? loggedIn : logIn
}
