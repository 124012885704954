module.exports = [
  {
    plugin: require("/Users/michal/work/bite-api/frontend/dashboard/node_modules/gatsby-mdx/gatsby-browser.js"),
    options: {
      plugins: [],
      defaultLayouts: {
        default:
          "/Users/michal/work/bite-api/frontend/dashboard/src/components/layout/dashboard.js"
      },
      gatsbyRemarkPlugins: [{ resolve: "gatsby-remark-prismjs" }]
    }
  },
  {
    plugin: require("/Users/michal/work/bite-api/frontend/dashboard/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js"),
    options: {
      plugins: [],
      trackingId: "UA-76149572-3",
      head: true,
      anonymize: true,
      respectDNT: true,
      siteSpeedSampleRate: 10
    }
  },
  {
    plugin: require("/Users/michal/work/bite-api/frontend/dashboard/gatsby-browser.js"),
    options: { plugins: [] }
  }
];
