import React from 'react'

const whitespace = /\s/g
const specialChars = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,./:;<=>?@[\]^`{|}~’]/g

const generateSlug = s =>
  s
    .toLowerCase()
    .trim()
    .replace(specialChars, '')
    .replace(whitespace, '-')

// unfortunately the remark autolink plugin doesn't work
// with mdx so we have to generate the slugs
// see: https://github.com/ChristopherBiscardi/gatsby-mdx/issues/204
// NOTE: this doesn't handle repeated headings
const AutoLinkHeader = ({ is: Component, id, ...props }) => (
  <Component id={id || generateSlug(props.children)} {...props} />
)
AutoLinkHeader.defaultProps = { is: 'h2' }

export const components = {
  h1: props => <AutoLinkHeader is="h1" {...props} />,
  h2: props => <AutoLinkHeader is="h2" {...props} />,
  h3: props => <AutoLinkHeader is="h3" {...props} />
}
