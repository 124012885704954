// prefer default export if available
const preferDefault = m => (m && m.default) || m;

exports.components = {
  "component---src-pages-404-js": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-applications-js": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/applications.js" /* webpackChunkName: "component---src-pages-applications-js" */),
  "component---src-pages-billing-js": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/billing.js" /* webpackChunkName: "component---src-pages-billing-js" */),
  "component---src-pages-demos-js": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/demos.js" /* webpackChunkName: "component---src-pages-demos-js" */),
  "component---src-pages-forgot-password-js": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/forgot_password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-reset-password-js": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/reset_password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-signup-js": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-tokens-js": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/tokens.js" /* webpackChunkName: "component---src-pages-tokens-js" */),
  "component---src-pages-docs-client-libraries-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/client-libraries.mdx" /* webpackChunkName: "component---src-pages-docs-client-libraries-mdx" */),
  "component---src-pages-docs-authentication-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/authentication.mdx" /* webpackChunkName: "component---src-pages-docs-authentication-mdx" */),
  "component---src-pages-docs-food-logging-index-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/food_logging/index.mdx" /* webpackChunkName: "component---src-pages-docs-food-logging-index-mdx" */),
  "component---src-pages-docs-food-logging-log-with-barcode-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/food_logging/log_with_barcode.mdx" /* webpackChunkName: "component---src-pages-docs-food-logging-log-with-barcode-mdx" */),
  "component---src-pages-docs-food-logging-logging-a-meal-with-an-image-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/food_logging/logging_a_meal_with_an_image.mdx" /* webpackChunkName: "component---src-pages-docs-food-logging-logging-a-meal-with-an-image-mdx" */),
  "component---src-pages-docs-food-logging-logging-a-meal-with-text-search-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/food_logging/logging_a_meal_with_text_search.mdx" /* webpackChunkName: "component---src-pages-docs-food-logging-logging-a-meal-with-text-search-mdx" */),
  "component---src-pages-docs-food-recognition-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/food_recognition.mdx" /* webpackChunkName: "component---src-pages-docs-food-recognition-mdx" */),
  "component---src-pages-docs-image-input-in-the-api-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/image_input_in_the_api.mdx" /* webpackChunkName: "component---src-pages-docs-image-input-in-the-api-mdx" */),
  "component---src-pages-docs-index-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/index.mdx" /* webpackChunkName: "component---src-pages-docs-index-mdx" */),
  "component---src-pages-docs-knowledge-graph-creating-items-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/knowledge_graph/creating_items.mdx" /* webpackChunkName: "component---src-pages-docs-knowledge-graph-creating-items-mdx" */),
  "component---src-pages-docs-knowledge-graph-index-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/knowledge_graph/index.mdx" /* webpackChunkName: "component---src-pages-docs-knowledge-graph-index-mdx" */),
  "component---src-pages-docs-knowledge-graph-localization-and-translations-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/knowledge_graph/localization_and_translations.mdx" /* webpackChunkName: "component---src-pages-docs-knowledge-graph-localization-and-translations-mdx" */),
  "component---src-pages-docs-knowledge-graph-looking-up-items-by-ids-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/knowledge_graph/looking_up_items_by_ids.mdx" /* webpackChunkName: "component---src-pages-docs-knowledge-graph-looking-up-items-by-ids-mdx" */),
  "component---src-pages-docs-knowledge-graph-retrieving-item-details-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/knowledge_graph/retrieving_item_details.mdx" /* webpackChunkName: "component---src-pages-docs-knowledge-graph-retrieving-item-details-mdx" */),
  "component---src-pages-docs-user-administration-mdx": () =>
    import("/Users/michal/work/bite-api/frontend/dashboard/src/pages/docs/user_administration.mdx" /* webpackChunkName: "component---src-pages-docs-user-administration-mdx" */)
};
