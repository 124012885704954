import React from 'react'

export const GlobalContext = React.createContext({
  defaultProgrammingLanguage: 'python'
})

export class GlobalContextManager extends React.Component {
  constructor(props) {
    super(props)

    this.update = (key, value) => {
      console.debug(
        `updating global context variable "${key}" from`,
        this.state[key],
        'to',
        value
      )
      this.setState({ [key]: value })
    }

    this.state = {
      defaultProgrammingLanguage: 'python',
      update: this.update
    }
  }

  render() {
    console.debug('context:', this.state)
    return (
      <GlobalContext.Provider value={this.state}>
        {this.props.children}
      </GlobalContext.Provider>
    )
  }
}
