import React from 'react'
import { GlobalContextManager } from './src/context'
import { MDXProvider } from '@mdx-js/react'
import { ApolloProvider } from 'react-apollo'
import { components as mdxComponents } from './src/components/mdx-overrides'
import DeveloperAPI from './src/services/DeveloperAPIClient'
import { AuthRedirect } from './src/components/auth'

// make global context available on all pages
export const wrapRootElement = ({ element, ...rest }) => (
  <ApolloProvider client={DeveloperAPI.createApolloClient()}>
    <AuthRedirect>
      <MDXProvider components={mdxComponents}>
        <GlobalContextManager>{element}</GlobalContextManager>
      </MDXProvider>
    </AuthRedirect>
  </ApolloProvider>
)
